import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import PageWrapper from "../components/PageWrapper"
import InnerHero from "../components/InnerHero"
import Testimonial2 from "../sections/testimonials/Testimonial2"

const TestimonialPage1 = ({ data }) => {
  return (
    <>
      <PageWrapper
        footerConfig={{
          hasTop: true,
          hasBottomSocial: true,
          bottomClassName: "bg-primary-25",
          className: "!bg-primary-50",
        }}
      >
        <GatsbySeo
          title={`Kloft - Testimonial 01`}
          description={`We serve globally to modernise static sites to take advantage of the blazing speeds of JAMStack while eliminating monthly maintenance and even probably, server costs.`}
        />
        <InnerHero
          title="Testimonial 01"
          text="Organically grow the holistic world view of disruptive innovation via workplace diversity and empowerment."
        />
        <Testimonial2 />
      </PageWrapper>
    </>
  )
}

export default TestimonialPage1
